<template>
  <div class="col">
    <div v-if="heathiansBottom && heathiansBottom.url">
      <b-img
        fluid
        class="w-100 h-100"
        :src="heathiansBottom.url.fileUrl"
      ></b-img>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  name: "healthians-bottom-banner",
  computed: {
    ...mapState({
      heathiansBottom: ({ advertisement }) => advertisement.heathiansBottom,
    }),
  },
  methods: {
    ...mapActions({
      fetchHealthiansBottomBannerData: "advertisement/healthiansWebBottom",
    }),
  },
  mounted() {
    this.fetchHealthiansBottomBannerData();
  },
};
</script>
